<template>
  <div>
    <span class="back" @click="$router.go(-1)"> <font-awesome-icon icon="chevron-left" /> Volver </span>
    <div class="option-container">
      <step :step="1" />
      <loading :active.sync="loading" :is-full-page="true"></loading>
      <branch-office-flow />
    </div>
  </div>
</template>

<script>
import BranchOfficeFlow from "@/components/BranchOfficeFlow";
import Step from "@/components/Step";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Api from "@/api";
const api = new Api();
export default {
  name: "VeterinarianService",
  components: { BranchOfficeFlow, Loading, Step },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["isFiltered", "isDataLoaded", "schedules"]),
    ...mapGetters({ form: "queryForm" }),
  },
  created() {
    this.getDataSelects();
  },
  methods: {
    async getDataSelects() {
      const isSpecialty = "general";
      this.loading = true;
      const response = await api.getInitData(isSpecialty);
      if (response.status == 200) {
        const data = await response.data;
        this.setSelectsData(data);
        this.$store.commit("setIsdataLoaded", true);
        this.$store.commit("setIsFiltereds", false);
      }
      if (this.isFiltered || !this.isDataLoaded) {
        const response = await api.getInitData(isSpecialty);
        if (response.status == 200) {
          const data = await response.data;
          this.setSelectsData(data);
          this.$store.commit("setIsdataLoaded", true);
          this.$store.commit("setIsFiltereds", false);
        }
      } else {
        this.$store.commit("resetInitData");
      }
      this.loading = false;
    },
    setSelectsData(data) {
      const firstValue = {
        id: "0",
        name: "No tengo preferencia",
      };
      const firstValueAuxiliar = {
        id: "0",
        name: "No tengo preferencia",
        descripcion: "No tengo preferencia",
      };
      this.$store.commit("setInitData", {
        veterinaries: [firstValue].concat(data.veterinaries.filter(x=>![297,309,343,310,18,363].includes(x.id))),
        branchs: [firstValueAuxiliar].concat(data.branch),
        specialties: [firstValue].concat(data.specialties),
      });
      this.$store.commit("setReasons", data.reasons);
      this.$store.commit("setSpecies", data.species);
      this.$store.commit("resetInitData");
    },
  },
};
</script>

<style scoped>
.header {
  text-align: center;
}
form {
  padding-bottom: 50px;
}
.link-text {
  position: relative;
  color: black;
  font-size: 24px;
  font-weight: bolder;
  font-style: italic;
  left: 9%;
}
a:link,
a:visited,
a:active {
  text-decoration: none;
}
.options {
  display: flex;
  padding-top: 2%;
}
.option-container {
  position: relative;
  text-align: initial;
}
.item {
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 8% 7% 8% 2%;
  margin: 2%;
  width: 24%;
  background: rgba(255, 255, 255, 0.7);
}
@media (max-width: 425px) {
  .back {
    top: 3%;
  }
}
</style>
