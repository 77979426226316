<template>
  <div class="form-container">
    <loading :active.sync="loading" :is-full-page="true"></loading>

    <form>
      <div class="form-row">
        <div class="form-group col-12 col-md-12">
          <label>Sucursal</label>
          <v-select
            class="search-input"
            :options="branchs"
            v-model="form.branch_office_id"
            :reduce="(branch) => branch.id"
            label="descripcion"
            @input="handleSelectOffice"
          ></v-select>
        </div>
      </div>
      <div class="form-row" v-if="OfficeSelected">
        <div class="form-group col-12 col-md-12">
          <label for="inputState">Veterinario</label>
          <v-select
            class="search-input"
            :options="veterinaries"
            v-model="form.veterinary_id"
            :reduce="(vet) => vet.id"
            label="name"
            @input="handleSelectVeterinary"
          ></v-select>
        </div>
        </div>
        <div class="form-row"><!--
        <div class="form-group col-12 col-md-12">
          <label>Especialidad</label>
          <v-select
            class="search-input"
            :options="specialties"
            v-model="form.specialty_id"
            :reduce="(spe) => spe.id"
            label="name"
            @input="handleSelectSpecialty"
          ></v-select>
          <<div v-if="SchedulingSpecialty">
        <p>Te recordamos que esta consulta de especialistas tiene un costo asociado, puedes consultar llamando a tu local más cercano.</p>
        </div>
    </div> -->
      </div>
      <!--<div class="form-row">
        <div class="form-group col-12 col-md-6">
          <label>Desde la fecha</label>
          <input type="date" class="form-control" :min="getNow()" v-model="form.startDay" />
        </div>
        <div class="form-group col-12 col-md-6  ">
          <label>Hasta la fecha</label>
          <input
            type="date"
            :min="form.startDay ? form.startDay : getNow()"
            class="form-control"
            v-model="form.endDay"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-12 col-md-6">
          <label>Desde la hora</label>
          <select class="form-control" v-model="form.startHour" @change="changeSelectStartHour()">
            <option value="">--:--</option>
            <option v-for="hour in hours" :key="hour.id" :value="hour.value">{{ hour.value }}</option>
          </select>
        </div>
        <div class="form-group col-12 col-md-6  ">
          <label>Hasta la hora</label>
          <select class="form-control" v-model="form.endHour">
            <option value="">--:--</option>
            <option v-for="hour in endHours" :key="hour.id" :value="hour.value">{{ hour.value }}</option>
          </select>
        </div>
	</div>-->
      <div class="form-row align-items-center">
        <div class="col pt-4">
          <button
            type="button"
            @click="search"
            class="btn btn-primary btn-green btn-lg btn-block"
            :disabled="isValid()"
          >
            Buscar horas
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import dayjs from "dayjs";
import { mapGetters } from "vuex";
var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
dayjs.extend(isSameOrAfter);
import Api from "@/api";
const api = new Api();
const VETERINARY = 2;
const BRANCH = 1;
const SPECIALTY = 3;
export default {
  name: "BranchOfficeFlow",
  components: { Loading },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      filter: {
        first: "",
        second: "",
        third: "",
      },
      endHours: [],
      hours: [
        {
          id: 1,
          value: "08:00",
        },
        {
          id: 2,
          value: "08:30",
        },
        {
          id: 3,
          value: "09:00",
        },
        {
          id: 4,
          value: "09:30",
        },
        {
          id: 5,
          value: "10:00",
        },
        {
          id: 6,
          value: "10:30",
        },
        {
          id: 7,
          value: "11:00",
        },
        {
          id: 8,
          value: "11:30",
        },
        {
          id: 9,
          value: "12:00",
        },
        {
          id: 10,
          value: "12:30",
        },
        {
          id: 11,
          value: "13:00",
        },
        {
          id: 12,
          value: "13:30",
        },
        {
          id: 13,
          value: "14:00",
        },
        {
          id: 14,
          value: "14:30",
        },
        {
          id: 15,
          value: "15:00",
        },
        {
          id: 16,
          value: "15:30",
        },
        {
          id: 17,
          value: "16:00",
        },
        {
          id: 18,
          value: "16:30",
        },
        {
          id: 19,
          value: "17:00",
        },
        {
          id: 20,
          value: "17:30",
        },
        {
          id: 21,
          value: "18:00",
        },
        {
          id: 22,
          value: "18:30",
        },
        {
          id: 23,
          value: "19:00",
        },
        {
          id: 24,
          value: "19:30",
        },
      ],
      loading: false,
      SchedulingSpecialty: false,
      OfficeSelected: false,
    };
  },
  created() {
    // this.getBranchOficces();
    // this.form.specialty_id = "1";
    // this.filter.first = SPECIALTY;
    // this.getDataBySpecialty(this.form.specialty_id);
  },
  mounted() {
    this.form.specialty_id = 1;
    this.queryForm.type = SPECIALTY;
    this.handleSelectSpecialty();

  },
  computed: {
    ...mapGetters(["queryForm"]),
    ...mapGetters({
      form: "queryForm",
      branchs: "branchs",
      specialties: "specialties",
      veterinaries: "veterinaries",
    }),
  },
  methods: {
    async getBranchOficces() {
      this.loading = true;
      const { data } = await api.getAllBranchOffices();
      this.$store.commit("updateBranchs", data);
      this.loading = false;
    },
    async handleSelectOffice() {
      this.OfficeSelected = false;
      this.form.veterinary_id = "0";
      this.form.specialty_id = 1;
      this.queryForm.type = SPECIALTY;
      this.loading = true;
      if (this.form.branch_office_id === "" || parseInt(this.form.branch_office_id) == 0) {
        if (this.queryForm.type == BRANCH) {
          this.$store.commit("resetInitData");
          this.resetFilter();
        } else if (this.filter.second == BRANCH) {
          this.resetFilter();
          
            await this.getDataBySpecialty();
          
        } else {
          this.form.branch_office_id = "0";
        }
      } else if (parseInt(this.form.branch_office_id) > 0) {
        this.OfficeSelected = true;
        if (this.typeSearchIsNotSelected() || this.queryForm.type == BRANCH) {
          await this.getDataByOffice();
        } else if (this.filter.second == "" || this.filter.second == BRANCH) {
          this.filter.second = BRANCH;
          
            await this.getVeterinaryByBranchAndSpecialty();
          
        }
      }
      this.loading = false;
    },
    changeSelectStartHour() {
      this.form.endHour = "";
      const selected = this.hours.find((x) => x.value === this.form.startHour);
      this.endHours = this.hours.filter((x) => x.id > selected.id);
    },
    resetFilter() {
      this.filter = {
        first: "",
        second: "",
        third: "",
      };
    },
    async getDataByOffice(office = this.form.branch_office_id) {
      const isSpecialty = 'general';
      if (office == null) return false;
      this.$store.commit("setTypeSearch", BRANCH);
      const response = await api.getDataByBranch(office,isSpecialty);
      if (response.status == 200) {
        const data = await response.data;
        this.$store.commit("updateVeterinaries", data.veterinaries);
        this.$store.commit("updateSpecialties", data.specialties);
      }
    },
    async getVeterinaryByBranchAndSpecialty() {
      if (this.form.branch_office_id == null || this.form.specialty_id == null) return false;
      const response = await api.getVeterinaryByBranchAndSpecialty(this.form.branch_office_id, this.form.specialty_id);
      if (response.status == 200) {
        const data = await response.data;
        this.$store.commit("updateVeterinaries", data);
      }
    },
    async handleSelectVeterinary() {
      this.form.specialty_id = 1;
      this.queryForm.type = SPECIALTY;
      this.loading = true;
      if (this.form.veterinary_id === "" || this.form.veterinary_id == "0") {
        if (this.queryForm.type == VETERINARY) {
          this.$store.commit("resetInitData");
          this.resetFilter();
        } else if (this.filter.second == VETERINARY) {
          this.resetFilter();
          if (this.queryForm.type == BRANCH) {
            await this.getDataByOffice();
          } else {
            await this.getDataBySpecialty();
          }
        } else {
          this.form.veterinary_id = "0";
        }
      }
      this.loading = false;
    },
    async handleSelectSpecialty() {
      this.form.specialty_id = 1;
      this.queryForm.type = SPECIALTY;
      this.loading = true;
      if (this.form.specialty_id != "" && this.form.specialty_id != "0" && this.form.specialty_id != "1") {
      this.SchedulingSpecialty = true;
      }
      else {
      this.SchedulingSpecialty = false;
      }
      if (this.form.specialty_id === "" || this.form.specialty_id == "0") {
        if (this.queryForm.type == SPECIALTY) {
          this.$store.commit("resetInitData");
          this.resetFilter();
        } else if (this.filter.second == SPECIALTY) {
          this.filter.second = "";
          this.resetFilter();
          if (this.queryForm.type == BRANCH) {
            await this.getDataByOffice();
          } 
        } else {
          this.form.specialty_id = "0";
        }
      } else {
        if (this.typeSearchIsNotSelected() || this.queryForm.type == SPECIALTY) {
          await this.getDataBySpecialty();
        } else if (this.filter.second == "" || this.filter.second == SPECIALTY) {
          this.filter.second = SPECIALTY;
          if (this.queryForm.type == BRANCH) {
            await this.getVeterinaryByBranchAndSpecialty();
          }
        }
      }
      this.loading = false;
    },
    typeSearchIsNotSelected() {
      return this.queryForm.type == "";
    },
    async getDataBySpecialty(specialty = this.form.specialty_id) {
      if (specialty == null) return false;
      this.$store.commit("setTypeSearch", SPECIALTY);
      const response = await api.getDataBySpecialty(specialty);
      if (response.status == 200) {
        const data = await response.data;
        this.$store.commit("updateVeterinaries", data.veterinaries);
        this.$store.commit("updateBranchs", data.branch);
      }
    },
    setSelectsData(data) {
      this.$store.commit("updateBranchs", data.branch);
      this.$store.commit("updateVeterinaries", data.veterinaries);
      this.$store.commit("updateSpecialties", data.specialties.filter(x=>x.id==1));
    },
    getDate(date) {
      return date.substring(0, 10);
    },
    getNow() {
      return dayjs().format("YYYY-MM-DD");
    },
    mapDataSchedule(data) {
      const schedules = data.reduce((acc, el) => {
        const day = this.getDate(el.day);
        if (acc[day]) {
          const index = acc[day].professionals.findIndex((x) => x.userId == el.user.id);
          if (acc[day].professionals[index]) {
            let i = day + "--" + el.branch_office.id;
            const indexElement = acc[day].professionals[index].schedules.findIndex((x) => x.id === i);
            if (acc[day].professionals[index].schedules[indexElement]) {
              acc[day].professionals[index].schedules[indexElement].hours = acc[day].professionals[index].schedules[
                indexElement
              ].hours.concat([
                ...el.schedules_availables.reduce((acc, el) => {
                  const item = this.verifyDataSelect(el);
                  if (item) {
                    acc.push(item);
                  }
                  return acc;
                }, []),
              ]);
            } else {
              const objSchedule = this.getObjectSchedule(el);
              if (objSchedule.hours.length > 0) {
                acc[day].professionals[index].schedules.push(objSchedule);
              }
            }
          } else {
            const objSchedule = this.getObjectSchedule(el);
            if (objSchedule.hours.length > 0) {
              acc[this.getDate(el.day)].professionals.push({
                userId: el.user.id,
                name: el.user.professional.first_name + " " + el.user.professional.last_name,
                specialties: [],
                // specialties: [el.user.professional.specialtys.map((item) => item.name)],
                office: el.branch_office,
                schedules: [objSchedule],
              });
            }
          }
        } else {
          const objSchedule = this.getObjectSchedule(el);
          if (objSchedule.hours.length > 0) {
            acc[this.getDate(el.day)] = {
              professionals: [
                {
                  userId: el.user.id,
                  name: el.user.professional.first_name + " " + el.user.professional.last_name,
                  specialties: [],
                  // specialties: [el.user.professional.specialtys.map((item) => item.name)],
                  office: el.branch_office,
                  schedules: [objSchedule],
                },
              ],
            };
          }
        }
        return acc;
      }, {});
      this.$store.commit("updateSchedules", schedules);
      this.$store.commit("updateDaysAvailable", Object.keys(schedules));
      this.$router.push("/search-date");
    },
    getObjectSchedule(el) {
      return {
        id: this.getDate(el.day) + "--" + el.branch_office.id,
        day: el.day,
        office: el.branch_office,
        hours: [
          ...el.schedules_availables.reduce((acc, el) => {
            const item = this.verifyDataSelect(el);
            if (item) {
              acc.push(item);
            }
            return acc;
          }, []),
        ],
      };
    },
    verifyDataSelect(item) {
      const today = dayjs().format("YYYY-MM-DD");
      const hourLimit = dayjs().add(15, "m");
      if (item.day === today) {
        const hours = item.start.split(":");
        const itemHour = dayjs()
          .hour(hours[0])
          .minute(hours[1]);
        if (itemHour.isSameOrAfter(hourLimit)) {
          return { id: item.id, start: item.start, schedule: item.schedule_id };
        }
        return false;
      } else {
        return { id: item.id, start: item.start, schedule: item.schedule_id };
      }
    },
    isValid() {
      return !(
        (this.form.branch_office_id != "0" && this.form.branch_office_id != null && this.form.branch_office_id != "") ||
        (this.form.veterinary_id != "0" && this.form.veterinary_id != null && this.form.veterinary_id != "") ||
        (this.form.specialty_id != "0" && this.form.specialty_id != null && this.form.specialty_id != "") ||
        this.form.startDay != "" ||
        this.form.endDay != "" ||
        this.form.startHour != "" ||
        this.form.endHour != ""
      );
    },
    // cleanData(schedule) {
    //   let first = false;
    //   for (let i = 0; i < item.schedules.length; i++) {
    //     const item = item.schedules[i].hours.find((x) => x != false);
    //     if (item) {
    //       first = true;
    //       break;
    //     }
    //   }
    //   return first;
    // },
    async search() {
      this.loading = true;
      this.$store.commit("updateSchedules", []);
      this.$store.commit("setBranch", this.form.branch_office_id);
      this.$store.commit("setVeterinary", this.form.veterinary_id);
      this.$store.commit("setSpecialty", this.form.specialty_id);
      this.$store.commit("setStartDay", this.form.startDay);
      this.$store.commit("setEndDay", this.form.endDay);
      this.$store.commit("setStartHour", this.form.startHour);
      this.$store.commit("setEndHour", this.form.endHour);
      const response = await api.findSchedule(this.queryForm);
      if (response.status == 200) {
        let data = await response.data.data;
        data = data.filter((x) => x.schedules_availables.length > 0);
        this.mapDataSchedule(data);
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.search {
  margin-top: 30px;
  padding: 19px;
}
.search-container > .el-form-item {
  width: 100% !important;
}
.btn-green {
  background-color: #4a7d44;
  border-color: #4a7d44;
}
.btn-green:hover {
  background-color: #4a7d44;
  border-color: #4a7d44;
}
label {
  font-weight: bold;
}
.form-control {
  height: calc(1.5em + 0.75rem + 11px);
}
</style>
<style>
.search-input .vs__search::placeholder,
.search-input .vs__dropdown-toggle,
.search-input .vs--searchableu {
  padding: 0.575rem 0.75rem !important;
}
</style>
